<template>
	<!-- 保证金等级编辑 -->
	<div class="storeBondLevelEdit el-content">
		<a-form :label-col="{span:2}" :wrapper-col="{span:8}">
			<a-tabs defaultActiveKey>
			    <a-tab-pane key="first" tab="基本信息">
					<a-form-item label="等级名称">
						<a-input v-model:value="form.level_name"></a-input>
					</a-form-item>
					<a-form-item label="等级logo">
						<kd-img-select :src="form.level_logo" @change="(url)=>{ form.level_logo = url }"></kd-img-select>
					</a-form-item>
					<a-form-item label="等级描述">
						<a-input v-model:value="form.level_desc"></a-input>
					</a-form-item>
					<a-form-item label="最低保证金金额">
						<a-input v-model:value="form.where_min_amount" addon-after="元"></a-input>
					</a-form-item>
					<a-form-item label="等级详情">
						<tinymce-editor v-model="form.level_detail"></tinymce-editor>
					</a-form-item>
				</a-tab-pane>
			    <a-tab-pane key="second" tab="特权设置">
					<a-form-item label="商品免审核">
						<a-radio-group v-model:value="form.setting.shop_goods_not_audit">
						    <a-radio :value="1">开启</a-radio>
						    <a-radio :value="0">关闭</a-radio>
						</a-radio-group>
					</a-form-item>
					<a-form-item label="活动免审核">
						<a-radio-group v-model:value="form.setting.shop_event_not_audit">
						    <a-radio :value="1">开启</a-radio>
						    <a-radio :value="0">关闭</a-radio>
						</a-radio-group>
					</a-form-item>
					<a-form-item label="认养免审核">
						<a-radio-group v-model:value="form.setting.shop_adopt_not_audit">
						    <a-radio :value="1">开启</a-radio>
						    <a-radio :value="0">关闭</a-radio>
						</a-radio-group>
					</a-form-item>
					<a-form-item label="土地免审核">
						<a-radio-group v-model:value="form.setting.shop_land_not_audit">
						    <a-radio :value="1">开启</a-radio>
						    <a-radio :value="0">关闭</a-radio>
						</a-radio-group>
					</a-form-item>
					<a-form-item label="监控免审核">
						<a-radio-group v-model:value="form.setting.shop_live_not_audit">
						    <a-radio :value="1">开启</a-radio>
						    <a-radio :value="0">关闭</a-radio>
						</a-radio-group>
					</a-form-item>
				</a-tab-pane>
			</a-tabs>
			<a-form-item :wrapper-col="{offset:2}">
				<a-space>
					<a-button type="primary" @click="saveSubmit" >保存</a-button>
					<a-button @click="$router.go(-1)">返回</a-button>
				</a-space>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import shopModel from '@/api/addons/shop'
export default{
	setup(){
		const _d = reactive({
			form:{
				level_id:0,
				level_name:'',
				level_logo:'',
				level_desc:'',
				level_detail:'',
				where_min_amount:'',
				setting:{
					shop_not_audit:0,
					shop_goods_not_audit:0,
					shop_event_not_audit:0,
					shop_adopt_not_audit:0,
					shop_land_not_audit:0,
					shop_live_not_audit:0,
				}
			}
		})	
		const opt = useRoute().query
		if( opt.level_id ) shopModel.getBondLevelDetail(opt.level_id,res=>{
			_d.form.level_id = res.level_id
			_d.form.level_name = res.level_name
			_d.form.level_logo = res.level_logo
			_d.form.level_desc = res.level_desc
			_d.form.level_detail = res.level_detail
			_d.form.shop_not_audit = res.shop_not_audit
			_d.form.where_min_amount = res.where_min_amount
			_d.form.setting = res.setting
		})

		const saveSubmit = ()=>shopModel.addOrEditBondLevel(_d.form)

		return{
			...toRefs(_d),
			saveSubmit
		}
	}
}
</script>

<style lang="scss">
	
</style>
